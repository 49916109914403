export default {
  data() {
    return {
      texts: "",
      sFeesId: "",
      sFeeTypeId: "",
      screenWidth: 0,
      arrDetail: "",
      dataTable: [],
      dataTableRecord: [],
      bLoading: false,
      iItemsPerPage: 8,
      iNumPages: 0,
      iCurrentPage: 1,
      iTotalItems: 0,
      detailsOrEdit: false, //False is details
      optionsCurrency: {
        locale: "en-US",
        currency: "USD",
        currencyDisplay: "hidden",
        hideCurrencySymbolOnFocus: false,
        hideGroupingSeparatorOnFocus: true,
        hideNegligibleDecimalDigitsOnFocus: true,
        autoDecimalDigits: false,
        autoSign: true,
        useGrouping: true,
        accountingSign: false,
      },
      dValue: 0,
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
    this.close();
  },
  beforeMount() {
    this.texts = FILE.commissionsTexts[this.selectLanguage];
  },
  methods: {
    close() {
      this.detailsOrEdit = false;
      this.iCurrentPage = 1;
      this.dataTableRecord = [];
      this.$store.commit("setdialogDetailsCommissionsAddEdit", {
        active: false,
        arr: [],
      });
    },
    toModify() {
      this.detailsOrEdit = true;
    },
    handleResize() {
      if (window.innerWidth <= 500) {
        this.screenWidth = 70;
      } else if (window.innerWidth >= 960) {
        this.screenWidth = 50;
      } else {
        this.screenWidth = 80;
      }
    },
    getHistoryCommissions() {
      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/fees/${this.sFeesId}/record/${this.sFeeTypeId}`,
        {
          headers: {
            Authorization: "Bearer " + this.$store.state.sToken,
          },
          params: {
            iPageNumber: this.iCurrentPage,
            iItemsPerPage: this.iItemsPerPage,
          },
        }
      )
        .then((response) => {
          this.dataTableRecord = response.data.results;
          this.iNumPages = response.data.iNumPages;
          this.iTotalItems = response.data.iTotal;
          this.$store.commit("refresher", false);
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    getHistoryExchangeRates() {
      DB.get(`${URI}/api/v1/${this.selectLanguage}/exchange-rates`, {
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
        params: {
          iPageNumber: this.iCurrentPage,
          iItemsPerPage: this.iItemsPerPage,
        },
      })
        .then((response) => {
          this.dataTableRecord = response.data.results;

          this.iNumPages = response.data.iNumPages;
          this.iTotalItems = response.data.iTotal;
          this.$store.commit("refresher", false);
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    getHistoryInOut() {
      DB.get(`${URI}/api/v1/${this.selectLanguage}/in-out-rates`, {
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
        params: {
          // iPageNumber: this.iCurrentPage,
          // iItemsPerPage: this.iItemsPerPage,
        },
      })
        .then((response) => {
          this.dataTableRecord = response.data.results;

          this.iNumPages = response.data.iNumPages;
          this.iTotalItems = response.data.iTotal;
          this.$store.commit("refresher", false);
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    saveChange() {
      // this.$store.commit("refresher", true);

      if (this.arrDetail.AllbType == 1) {
        this.saveChangeExchangeRates();
      } else if (this.arrDetail.AllbType == 2) {
        this.saveChangeInOut();
      } else {
        this.saveChangeFees();
      }
    },
    saveChangeExchangeRates() {
      const config = {
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
      };
      const payload = {
        dRatePerUSD: this.dValue,
      };
      DB.post(
        `${URI}/api/v1/${this.selectLanguage}/exchange-rates`,
        payload,
        config
      )
        .then((response) => {
          this.detailsOrEdit = false;
          this.arrDetail.dRatePerUSD = response.data.results.dRatePerUSD;

          this.mixSuccess(response.data.message);
          if (this.arrDetail.AllbType == 1) {
            this.getHistoryExchangeRates();
          } else {
            this.getHistoryCommissions();
          }
          this.iCurrentPage = 1;
          this.$store.commit("refresher", true);
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    saveChangeFees() {
      const config = {
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
      };
      const payload = {
        dValue: this.dValue,
      };


      DB.post(
        `${URI}/api/v1/${this.selectLanguage}/fees/${this.sFeesId}/record/${this.sFeeTypeId}`,
        payload,
        config
      )
        .then((response) => {
          this.detailsOrEdit = false;
          this.arrDetail.dValue = response.data.results.dValue

          this.mixSuccess(response.data.message);
          if (this.arrDetail.AllbType == 1) {
            this.getHistoryExchangeRates();
          } else {
            this.getHistoryCommissions();
          }
          this.iCurrentPage = 1;
          this.$store.commit("refresher", true);
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    saveChangeInOut() {
      const config = {
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
      };
      const payload = {
        dRate: this.dValue,
      };

      DB.post(
        `${URI}/api/v1/${this.selectLanguage}/in-out-rates`,
        payload,
        config
      )
        .then((response) => {
          this.detailsOrEdit = false;
          this.arrDetail.dRate = response.data.results.dRate
          this.mixSuccess(response.data.message);
          this.getHistoryInOut();
          this.iCurrentPage = 1;
          this.$store.commit("refresher", true);
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    changeMontoGlobal(obj) {
      if (this.arrDetail.AllbType == 1) {
        this.dValue = parseFloat(obj.value);
      } else if (this.arrDetail.AllbType == 2) {
        this.dValue = parseFloat(obj.value);
      } else {
        this.dValue = parseFloat(obj.value);
      }
    },
  },
  computed: {
    selectLanguage() {
      return this.$store.state.language;
    },
    dialogDetailsCommissionsAddEdit() {
      return this.$store.state.dialogDetailsCommissionsAddEdit;
    },
    arrDetailComissionsAddEdit() {
      return this.$store.state.arrDetailComissionsAddEdit;
    },
  },
  watch: {
    iCurrentPage() {
      if (this.arrDetail.AllbType == 1) {
        this.getHistoryExchangeRates();
      } else if (this.arrDetail.AllbType == 2) {
        this.getHistoryInOut();
      } else {
        this.getHistoryCommissions();
      }
    },
    selectLanguage() {
      if (this.selectLanguage) {
        this.texts = FILE.commissionsTexts[this.selectLanguage];
      }
    },
    dialogDetailsCommissionsAddEdit: function() {
      if (this.dialogDetailsCommissionsAddEdit) {
        this.$store.commit("setMenuSidebar", { active: false });

        this.texts = FILE.commissionsTexts[this.selectLanguage];

        this.arrDetail = this.$store.state.arrDetailComissionsAddEdit;
        if (this.arrDetail.AllbType == 1) {
          this.dValue = this.arrDetail.dRatePerUSD;
          this.getHistoryExchangeRates();
        } else if (this.arrDetail.AllbType == 2) {
          this.dValue = this.arrDetail.dRate;
          this.getHistoryInOut();
        } else {
          this.dValue = this.arrDetail.dValue;
          (this.sFeesId = this.arrDetail.sFeeId),
            (this.sFeeTypeId = this.arrDetail.sFeeTypeId),
            this.getHistoryCommissions();
        }
      }
    },
    detailsOrEdit() {
      if (!this.detailsOrEdit) {
        if (this.arrDetail.AllbType == 1) {
          this.dValue = this.arrDetail.dRatePerUSD;
        } else if (this.arrDetail.AllbType == 2) {
          this.dValue = this.arrDetail.dRate;
        } else {
          this.dValue = this.arrDetail.dValue;
        }
      }
    },
  },
};
